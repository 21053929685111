import anime from '../libs/anime'
import globalVar from '../utils/globalVar'

class LocationsMenu {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
        cache.btn = document.querySelector('.template-single-locations .hero_container a.scroll');
        cache.menu = document.querySelector('.template-single-locations section.menu');
        cache.menuFilters = cache.menu.querySelectorAll('.filter');
        cache.subMenu = cache.menu.querySelectorAll('.sub .title');
    };

    let registerEvents = () => {
        for (let i = 0; i < cache.menuFilters.length; i++) {
            cache.menuFilters[i].addEventListener('click', switchFilter);
        }
        if (window.location.href.indexOf("#food") != -1) {
            cache.menu.querySelector('.filter_food').click();
            globalVar.scrollElement.scrollTop = (cache.menu.offsetTop);
        }
        if ((window.location.href.indexOf("#happy-hour") != -1) || (window.location.href.indexOf("#happy-hour-late-night") != -1)) {
            cache.menu.querySelector('.filter_happy-hour-late-night').click();
            globalVar.scrollElement.scrollTop = (cache.menu.offsetTop);
        }
        if (window.location.href.indexOf("#drink") != -1) {
            cache.menu.querySelector('.filter_drink').click();
            globalVar.scrollElement.scrollTop = (cache.menu.offsetTop);
        }

        if(cache.btn) {
            cache.btn.addEventListener('click', scrollTo);
        }

        for (let i = 0; i < cache.subMenu.length; i++) {
            cache.subMenu[i].addEventListener('click', toggleSub);
        }
    };

    let switchFilter = function() {
        const targetFilter = this;
        const targetFilterId = targetFilter.dataset.filter;
        const activeFilter = targetFilter.closest('.filter_wrap').querySelector('.filter.active');
        const activeFilterId = activeFilter.dataset.filter;
        targetFilter.classList.add('active');
        activeFilter.classList.remove('active');

        const targetMenu = cache.menu.querySelector('.menu_sec.'+targetFilterId);
        const activeMenu = cache.menu.querySelector('.menu_sec.'+activeFilterId);
       
        anime({
            targets: activeMenu,
            opacity: 0,
            translateY: 50,
            duration: 300,
            easing: 'easeInQuad',
            complete: function() {
                activeMenu.style.display = "none";
                targetMenu.style.display = "block";
                anime({
                    targets: targetMenu,
                    opacity: [0,1],
                    translateY: [50, 0],
                    duration: 500,
                    easing: 'easeOutQuad',
                });
            }
        });
    }

    let toggleSub = function() {
        const sub = this.closest('.sub');

        if(!sub.classList.contains('open')) {
            const parent = this.closest('.menu_sec');
            if(parent.querySelector('.sub.open')) {
                const prevSub = parent.querySelector('.sub.open');
                prevSub.classList.remove('open');
                const prevSubContent = prevSub.querySelector('.items_wrapper');
                anime({
                    targets: prevSubContent,
                    height: 0,
                    duration: 400,
                    easing: 'easeInOutSine',
                    complete: function() {
                        prevSubContent.style.display = "none";
                    }
                });
            }
            
            sub.classList.add('open');
            const subContent = sub.querySelector('.items_wrapper');
            subContent.style.display = "block";
            subContent.style.height = "auto";
            const height = subContent.offsetHeight;
            subContent.style.height = 0;
            anime({
                targets: subContent,
                height: [0, height],
                duration: 400,
                easing: 'easeInSine',
                complete: function() {
                    const pos = cache.menu.offsetTop+sub.offsetTop;
                    if((pos > globalVar.scrollElement.scrollTop+150) || pos < globalVar.scrollElement.scrollTop-150) {
                        anime({
                            targets: globalVar.scrollElement,
                            scrollTop: pos,
                            duration: 200,
                            easing: 'linear',
                        });
                    }
                }
            });
            
          } else {
            sub.classList.remove('open');
            const subContent = sub.querySelector('.items_wrapper');
            anime({
                targets: subContent,
                height: 0,
                duration: 400,
                easing: 'easeInOutSine',
                complete: function() {
                    subContent.style.display = "none";
                }
            });
          }
    }

    let scrollTo = function(e) {
        document.querySelector('body').classList.add('scroll');
        e.preventDefault();
        var target = document.querySelector(this.getAttribute("href"));
        anime({
            targets: globalVar.scrollElement,
            scrollTop: (globalVar.scrollElement.scrollTop+target.getBoundingClientRect().top),
            duration: 800,
            easing: 'easeOutQuad',
            complete: function () {
            document.querySelector('body').classList.remove('scroll');
            }
        });
    }

    initFunc();
  };
}
export { LocationsMenu }