
import { Nav } from './components/nav'
import { Animations } from './components/animations'
import { Module_05_Events } from './modules/module-05-events'
import { Module_04_locations } from './modules/module-04-locations'
import { Module_14_Faq } from './modules/module-14-faq'
import { Contact } from './pages/contact'
import { LocationsMenu } from './pages/single-loctions'
import { ArchiveLocations } from './pages/archive-locations'
import { ArchiveEventsPromotions } from './pages/archive-events-promotions'
import { Loader } from './components/loader'

class App {
  constructor() {
    const nav = new Nav;
    const animations = new Animations;

    if(document.querySelector('.loader_container')) {
      const loader = new Loader;
    }

    if(document.querySelector('.template-contact .drop_click')) {
      const contact = new Contact;
    }
    if(document.querySelector('.template-single-locations section.menu')) {
      const locationsMenu = new LocationsMenu;
    }

    if(document.querySelector('.module-05-events')) {
      const module_05_events = new Module_05_Events;
    }
    if(document.querySelector('.module-04-locations .slider')) {
      const module_04_locations = new Module_04_locations;
    }
    if(document.querySelector('.module-14-faq .faq')) {
      const module_14_Faq = new Module_14_Faq;
    }

    if(document.querySelector('.template-archive-locations .filter_form')) {
      const archiveLocations = new ArchiveLocations;
    }

    if(document.querySelector('.template-archive-events-promotions .filter_form')) {
      const archiveEventsPromotions = new ArchiveEventsPromotions;
    }
  }
}

window.onload = function() {
  let app = new App();
};
