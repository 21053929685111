import anime from '../libs/anime'
import globalVar from '../utils/globalVar'

class Module_14_Faq {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
        cache.qestions = document.querySelectorAll('.module-14-faq .faq .question');
        console.log(cache.qestions);
    };

    let registerEvents = () => {
        for (let i = 0; i < cache.qestions.length; i++) {
            cache.qestions[i].addEventListener('click', toggleFaq);
        }
    };


    let toggleFaq = function() {
      const faq = this.closest('.faq');

      if(!faq.classList.contains('open')) {
        const parent = this.closest('.faq_wrapper');
        if(parent.querySelector('.faq.open')) {
            const prevFaq = parent.querySelector('.faq.open');
            prevFaq.classList.remove('open');
            const prevFaqAnswer = prevFaq.querySelector('.answer');
            anime({
                targets: prevFaqAnswer,
                height: 0,
                duration: 400,
                easing: 'easeInOutSine',
                complete: function() {
                    prevFaqAnswer.style.display = "none";
                }
            });
        }
        
        faq.classList.add('open');
        const faqAnswer = faq.querySelector('.answer');
        faqAnswer.style.display = "block";
        faqAnswer.style.height = "auto";
        const height = faqAnswer.offsetHeight;
        faqAnswer.style.height = 0;

        anime({
            targets: faqAnswer,
            height: [0, height],
            duration: 400,
            easing: 'easeInSine',
            complete: function() {
              const pos = faq.offsetTop;
              if(globalVar.tablet.matches) {
                if((pos > globalVar.scrollElement.scrollTop+200) || pos < globalVar.scrollElement.scrollTop-200) {
                  anime({
                      targets: globalVar.scrollElement,
                      scrollTop: pos,
                      duration: 200,
                      easing: 'linear',
                  });
                }
              } else {
                if((pos > globalVar.scrollElement.scrollTop+500) || pos < globalVar.scrollElement.scrollTop-500) {
                  anime({
                      targets: globalVar.scrollElement,
                      scrollTop: pos,
                      duration: 200,
                      easing: 'linear',
                  });
                }
              }
          }
        });
      } else {
        faq.classList.remove('open');
        const faqAnswer = faq.querySelector('.answer');
        anime({
            targets: faqAnswer,
            height: 0,
            duration: 400,
            easing: 'easeInOutSine',
            complete: function() {
                faqAnswer.style.display = "none";
            }
        });
      }
    }


    initFunc();
  };
}
export { Module_14_Faq }
