import Hammer from '../libs/hammer'
import anime from '../libs/anime'
import globalVar from '../utils/globalVar'

class Module_04_locations {
  constructor() {
    let cache = {};
    const duration = 400;
    const delay = 150;
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
      cache.sliders = document.querySelectorAll('.module-04-locations .slider');
    };

    let registerEvents = () => {
        if(!globalVar.tablet.matches) {
            for (let i = 0; i < cache.sliders.length; i++) {
                cache.sliders[i].closest('.module-04-locations').querySelector('.arrow_right').addEventListener('click', slideRight);
                cache.sliders[i].closest('.module-04-locations').querySelector('.arrow_left').addEventListener('click', slideLeft);
                let hammertime = new Hammer(cache.sliders[i]);
                    hammertime.on('swipe', function(ev) {
                        if(ev.direction == 2) {
                            cache.sliders[i].closest('.module-04-locations').querySelector('.arrow_right').click();
                        }
                        if(ev.direction == 4) {
                            cache.sliders[i].closest('.module-04-locations').querySelector('.arrow_left').click();
                        }
                });
            }
        } else {
            for (let i = 0; i < cache.sliders.length; i++) {
                let hammertime = new Hammer(cache.sliders[i]);
                    hammertime.on('swipe', function(ev) {
                        if(ev.direction == 2) {
                            slideRightMobile(cache.sliders[i]);
                        }
                        if(ev.direction == 4) {
                            slideLeftMobile(cache.sliders[i]);
                        }
                });
            }
        }

    };

    let slideRight = function() {
        const arrow = this;
        const parent = this.closest('.module-04-locations').querySelector('.slider');
        const cards = parent.querySelectorAll('.card_loc');
        let index = parseInt(parent.dataset.index);
        arrow.removeEventListener('click', slideRight);

        const card1 = cards[index];
        anime({
            targets: card1,
            translateX: ["0%", "-25%"],
            scale:[1,0.98],
            opacity: [1,0],
            duration: duration,
            easing: 'easeInOutSine',
            complete: function() {
                card1.style.display = "none";
                card1.style.left = "auto";
                card1.style.right = "auto";
            }
        });

        if(index == cards.length-1) {
            index = 0;
        } else {
            index++;
        }
        const card2 = cards[index];
        anime({
            targets: card2,
            translateX: ["-50%", "-75%"],
            scale:[1,0.98],
            opacity: [1,0],
            delay: delay,
            duration: duration,
            easing: 'easeInOutSine',
            complete: function() {
                card2.style.display = "none";
                card2.style.left = "auto";
                card2.style.right = "auto";
            }
        });

        if(index == cards.length-1) {
            index = 0;
        } else {
            index++;
        }
        const card3 = cards[index];
        anime({
            targets: card3,
            translateX: ["0%", "-25%"],
            scale:[1,0.98],
            opacity: [1,0],
            delay: (delay * 2),
            duration: duration,
            easing: 'easeInOutSine',
            complete: function() {
                card3.style.display = "none";
                card3.style.left = "auto";
                card3.style.right = "auto";

                if(index == cards.length-1) {
                    index = 0;
                } else {
                    index++;
                }
                parent.dataset.index = index;
                console.log(index);
                const card4 = cards[index];
                card4.style.display = "block";
                card4.style.left = 0;
                anime({
                    targets: card4,
                    translateX: ["25%", "0%"],
                    scale:[0.98, 1],
                    opacity: [0,1],
                    duration: duration,
                    easing: 'easeInOutSine',
                });


                if(index == cards.length-1) {
                    index = 0;
                } else {
                    index++;
                }
                const card5 = cards[index];
                card5.style.display = "block";
                card5.style.left = "50%";
                anime({
                    targets: card5,
                    translateX: ["-25%", "-50%"],
                    scale:[0.98, 1],
                    opacity: [0,1],
                    delay: delay,
                    duration: duration,
                    easing: 'easeInOutSine',
                });


                if(index == cards.length-1) {
                    index = 0;
                } else {
                    index++;
                }
                const card6 = cards[index];
                card6.style.display = "block";
                card6.style.right = 0;
                anime({
                    targets: card6,
                    translateX: ["25%", "0%"],
                    scale:[0.98, 1],
                    opacity: [0,1],
                    delay: (delay * 2),
                    duration: duration,
                    easing: 'easeInOutSine',
                    complete: function() {
                        arrow.addEventListener('click', slideRight);
                    }
                });
            }
        });
    }


    let slideLeft = function() {
        const arrow = this;
        const parent = this.closest('.module-04-locations').querySelector('.slider');
        const cards = parent.querySelectorAll('.card_loc');
        let index = parseInt(parent.dataset.index);
        let cardI = index;
        arrow.removeEventListener('click', slideLeft);

        const card1 = cards[index];
        anime({
            targets: card1,
            translateX: ["0%", "25%"],
            scale:[1,0.98],
            opacity: [1,0],
            duration: duration,
            easing: 'easeInOutSine',
            delay: (delay * 2),
            complete: function() {
                card1.style.display = "none";
                card1.style.left = "auto";
                card1.style.right = "auto";

                if(index == 0) {
                    index = cards.length-1;
                } else {
                    index--;
                }
                const card4 = cards[index];
                card4.style.display = "block";
                card4.style.right = 0;
                anime({
                    targets: card4,
                    translateX: ["-25%", "0%"],
                    scale:[0.98, 1],
                    opacity: [0,1],
                    duration: duration,
                    easing: 'easeInOutSine',
                });


                if(index == 0) {
                    index = cards.length-1;
                } else {
                    index--;
                }
                const card5 = cards[index];
                card5.style.display = "block";
                card5.style.left = "50%";
                anime({
                    targets: card5,
                    translateX: ["-75%", "-50%"],
                    scale:[0.98, 1],
                    opacity: [0,1],
                    delay: delay,
                    duration: duration,
                    easing: 'easeInOutSine',
                });

                if(index == 0) {
                    index = cards.length-1;
                } else {
                    index--;
                }
                parent.dataset.index = index;
                const card6 = cards[index];
                card6.style.display = "block";
                card6.style.left = 0;
                anime({
                    targets: card6,
                    translateX: ["-25%", "0%"],
                    scale:[0.98, 1],
                    opacity: [0,1],
                    delay: (delay * 2),
                    duration: duration,
                    easing: 'easeInOutSine',
                    complete: function() {
                        arrow.addEventListener('click', slideLeft);
                    }
                });
            }
        });

        if(cardI == cards.length-1) {
            cardI = 0;
        } else {
            cardI++;
        }
        const card2 = cards[cardI];
        anime({
            targets: card2,
            translateX: ["-50%", "-25%"],
            scale:[1,0.98],
            opacity: [1,0],
            delay: delay,
            duration: duration,
            easing: 'easeInOutSine',
            complete: function() {
                card2.style.display = "none";
                card2.style.left = "auto";
                card2.style.right = "auto";
            }
        });

        if(cardI == cards.length-1) {
            cardI = 0;
        } else {
            cardI++;
        }
        const card3 = cards[cardI];
        anime({
            targets: card3,
            translateX: ["0%", "25%"],
            scale:[1,0.98],
            opacity: [1,0],
            duration: duration,
            easing: 'easeInOutSine',
            complete: function() {
                card3.style.display = "none";
                card3.style.left = "auto";
                card3.style.right = "auto";
            }
        });
    }




    // MOBILE

    let slideRightMobile = function(parent) {
        const cards = parent.querySelectorAll('.card_loc');
        let index = parseInt(parent.dataset.index);

        const card1 = cards[index];
        anime({
            targets: card1,
            left: -325,
            opacity: [1,0],
            duration: (duration*1.5),
            easing: 'easeInOutSine',
            complete: function() {
                card1.style.display = "none";
                card1.style.left = "auto";
            }
        });

        if(index == cards.length-1) {
            index = 0;
        } else {
            index++;
        }
        parent.dataset.index = index;
        const card2 = cards[index];
        anime({
            targets: card2,
            left: 0,
            delay: delay,
            duration: (duration*1.5),
            easing: 'easeInOutSine',
        });
        
        if(index == cards.length-1) {
            index = 0;
        } else {
            index++;
        }
        const card3 = cards[index];
        anime({
            targets: card3,
            left: 325,
            delay: (delay * 2.25),
            duration: (duration*1.5),
            easing: 'easeInOutSine',
        });

        if(index == cards.length-1) {
            index = 0;
        } else {
            index++;
        }
        const card4 = cards[index];
        card4.style.display = "block";
        card4.style.left = "975px";
        anime({
            targets: card4,
            opacity: [0,1],
            left: 650,
            delay: (delay * 2.5),
            duration: (duration*1.5),
            easing: 'easeInOutSine',
        });
    }

    let slideLeftMobile = function(parent) {
        const cards = parent.querySelectorAll('.card_loc');
        let index = parseInt(parent.dataset.index);
        let cardI = index;

        if(index == 0) {
            index = cards.length-1;
        } else {
            index--;
        }
        parent.dataset.index = index;
        const card4 = cards[index];
        card4.style.display = "block";
        card4.style.left = '-325px';
        anime({
            targets: card4,
            opacity:[0,1],
            scale: [0.95, 1],
            left: 0,
            delay: (delay * 1.5),
            duration: (duration*1.5),
            easing: 'easeInOutSine',
        });


        const card1 = cards[cardI];
        anime({
            targets: card1,
            left: 325,
            delay: delay,
            duration: (duration*1.5),
            easing: 'easeInOutSine',
        });

        if(cardI == cards.length-1) {
            cardI = 0;
        } else {
            cardI++;
        }
        const card2 = cards[cardI];
        anime({
            targets: card2,
            left: 650,
            delay: (delay/2),
            duration: (duration*1.5),
            easing: 'easeInOutSine',
        });
        
        if(cardI == cards.length-1) {
            cardI = 0;
        } else {
            cardI++;
        }
        const card3 = cards[cardI];
        anime({
            targets: card3,
            opacity: [1,0],
            left: 975,
            duration: (duration*1.5),
            easing: 'easeInOutSine',
            complete: function() {
                card3.style.display = "none";
                card3.style.left = "auto";
            }
        });

    }

    initFunc();
  };
}
export { Module_04_locations }