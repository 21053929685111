import utils from '../utils/utils'
import anime from '../libs/anime'
import globalVar from '../utils/globalVar'

class Loader {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      loader();
    }

    let initCache = () => {
        cache.loader = document.querySelector('.loader_container');
        cache.loaderFrame = cache.loader.querySelector('.loader .loader_frame');
        cache.loaderDog = cache.loader.querySelector('.loader .loader_dog_body');
        cache.loaderHat = cache.loader.querySelector('.loader .loader_dog_hat');
    };

    let loader = () => {
        anime({
            targets: cache.loaderHat,
            translateY: [0,-140],
            rotate: -10,
            duration: 200,
            delay: 1000,
            easing: 'easeOutQuad',
            complete: function() {
              anime({
                targets: cache.loaderHat,
                translateY: 600,
                rotate: 320,
                duration: 800,
                delay: 800,
                easing: 'easeInQuad',
              });
            }
          });
          anime({
            targets: cache.loaderDog,
            translateY: ['0%','200%'],
            duration: 600,
            delay: 1500,
            easing: 'easeInQuad',
            complete: function() {
                if(document.querySelector('.module-01-hero video')) {
                  document.querySelector('.module-01-hero video').currentTime = 0;
                }
            }
          });
          anime({
            targets: cache.loader,
            translateY: ['0%','-100%'],
            duration: 800,
            delay: 2600,
            easing: 'easeInQuad',
            complete: function() {
              cache.loader.style.display = "none";
            }
          });
          
    };
    

    initFunc();
  };
}
export { Loader }
