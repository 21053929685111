import utils from '../utils/utils'
import anime from '../libs/anime'
import globalVar from '../utils/globalVar'

class Animations {
  constructor() {
    let scrollPos;
    const wh = window.innerHeight;
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
        if(document.querySelector('.module-12-gallery')) {
            cache.gallery = document.querySelectorAll('.module-12-gallery');
        }
        if(document.querySelector('.module-01-hero.type-cook')) {
            cache.heroCook = document.querySelectorAll('.module-01-hero.type-cook');
        }
        if(document.querySelector('.anime-title')) {
            cache.animeTitle = document.querySelectorAll('.anime-title');
            for (let i = 0; i < cache.animeTitle.length; i++) {
                cache.animeTitle[i].innerHTML = cache.animeTitle[i].textContent.replace(/(\S*)/g, m => {
                    return `<span class="word">` +
                      m.replace(/(-|#|@)?\S(-|#|@)?/g, "<span class='letter'>$&</span>") +
                      `</span>`;
                });

                cache.animeTitle[i].style.opacity = 1;
            }
        }
        if(document.querySelector('.btn, .breadcrumb')) {
            cache.btn = document.querySelectorAll('.btn, .breadcrumb');
        }
        if(document.querySelector('.anime-texte')) {
            cache.animeTexte = document.querySelectorAll('.anime-texte');
        }

        if(document.querySelector('.anime-steps')) {
            cache.animeSteps = document.querySelectorAll('.anime-steps');
        }
       
        if(document.querySelector('.anime-steps-opacity')) {
            cache.animeStepsOpacity = document.querySelectorAll('.anime-steps-opacity');
        }

        if(document.querySelector('.anime-img')) {
            cache.animeImg = document.querySelectorAll('.anime-img');
        }

        if(document.querySelector('.template-single-locations .anime_icon1')) {
            cache.locIcon1 = document.querySelector('.anime_icon1');
        }

        if(document.querySelector('.template-single-locations .anime_icon2')) {
            cache.locIcon2 = document.querySelector('.anime_icon2');
        }
        
    };

    let registerEvents = () => {
        if(cache.gallery || cache.heroCook || cache.animeTitle || cache.btn || cache.animeTexte || cache.animeSteps || cache.animeStepsOpacity || cache.animeImg) {
            window.addEventListener('scroll', scrollDetect);
            scrollDetect();
        }
    };


    let scrollDetect = function() {
      let up = false;
      if(scrollPos > window.pageYOffset) {
        up = true;
      }
      scrollPos = window.pageYOffset;

// GALLERY ANIME
      if(cache.gallery) {
        for (let i = 0; i < cache.gallery.length; i++) {
            const img_arr = cache.gallery[i].querySelectorAll('.img_container');
            const logo_arr = cache.gallery[i].querySelectorAll('.tavern');
            for (let ii = 0; ii < img_arr.length; ii++) {
                const img = img_arr[ii];
                const img_pos = utils.getOffsetTop(img);
                if(((scrollPos + wh) > img_pos) && ( (scrollPos) < (img_pos + wh))) {
                    let percent = (((scrollPos + wh)-img_pos))/(wh*2);
                    const animeImg = anime({
                        targets: img,
                        translateY: ['100%','0%'],
                        duration: 1000,
                        easing: 'easeOutSine',
                        autoplay: false,
                    });
                    animeImg.seek((percent * ((ii/7)+1)) * animeImg.duration);
                }   
            }

            for (let ii = 0; ii < logo_arr.length; ii++) {
                const logo = logo_arr[ii];
                const logo_pos = utils.getOffsetTop(logo);

                if(((scrollPos + wh) > logo_pos) && ( (scrollPos) < (logo_pos + wh))) {
                    if(ii % 2 == 0) {
                        let percent1 = (((scrollPos + wh)-logo_pos))/(wh*2);
                        const animeLogo = anime({
                            targets: logo,
                            translateX: ['0%','-10%'],
                            duration: 100,
                            easing: 'easeOutSine',
                            autoplay: false,
                        });
                        animeLogo.seek((percent1) * animeLogo.duration);
                    } else {
                        let percent2 = (((scrollPos + wh)-logo_pos))/(wh*2);
                        const animeLogo = anime({
                            targets: logo,
                            translateX: ['0%','10%'],
                            duration: 100,
                            easing: 'easeOutSine',
                            autoplay: false,
                        });
                        animeLogo.seek((percent2) * animeLogo.duration);
                    }
                }
            }
        }
      }

      if(cache.heroCook) {
        for (let i = 0; i < cache.heroCook.length; i++) {
            const logo = cache.heroCook[i].querySelector('.tavern');
            const logo_pos = utils.getOffsetTop(cache.heroCook[i]);

            if(((scrollPos) > logo_pos) && ( (scrollPos) < (logo_pos + wh))) {

                let percent1 = (((scrollPos)-logo_pos))/(wh*2);
                const animeLogo = anime({
                    targets: logo,
                    translateX: ['0%','-60%'],
                    duration: 100,
                    easing: 'easeOutSine',
                    autoplay: false,
                });
                animeLogo.seek((percent1) * animeLogo.duration);
            
            }
        }
      }

      if(cache.animeTitle) {
        for (let i = 0; i < cache.animeTitle.length; i++) {
            const title = cache.animeTitle[i];
            const title_pos = utils.getOffsetTop(title);
            const letter = title.querySelectorAll('.letter');

            if(((scrollPos + wh) > title_pos) && ( (scrollPos) < (title_pos + wh)) && !title.classList.contains('anime')) {
                title.classList.add('anime');
                for (let ii = 0; ii < letter.length; ii++) {
                    anime({
                        targets: letter[ii],
                        opacity: [0,1],
                        translateX:[15,0],
                        delay: (30 *ii),
                        duration: 500,
                        easing: 'easeInOutSine',
                    });
                }
            }
        }
      }

      if(cache.btn) {
        for (let i = 0; i < cache.btn.length; i++) {
            const btn = cache.btn[i];
            const btn_pos = utils.getOffsetTop(btn);

            if(((scrollPos + wh) > btn_pos) && ( (scrollPos) < (btn_pos + wh)) && !btn.classList.contains('anime')) {
                btn.classList.add('anime');
                anime({
                    targets: btn,
                    opacity: [0,1],
                    duration: 400,
                    easing: 'easeOutSine',
                });
                anime({
                    targets: btn.querySelector('.btn_txt'),
                    letterSpacing: ['0.07em','0em'],
                    duration: 400,
                    delay:200,
                    easing: 'easeOutSine',
                });
            }
        }
      }

      if(cache.animeTexte) {
        for (let i = 0; i < cache.animeTexte.length; i++) {
            const txt = cache.animeTexte[i];
            const txt_pos = utils.getOffsetTop(txt);

            if(((scrollPos + (wh-100)) > txt_pos) && ( (scrollPos) < (txt_pos + wh)) && !txt.classList.contains('anime')) {
                txt.classList.add('anime');
                anime({
                    targets: txt,
                    opacity: [0,1],
                    translateY:[50, 0],
                    duration: 400,
                    easing: 'easeOutSine',
                });
            }
        }
      }

      if(cache.animeSteps) {
        for (let i = 0; i < cache.animeSteps.length; i++) {
            const stepContainer = cache.animeSteps[i];
            const stepContainer_pos = utils.getOffsetTop(stepContainer);
            const steps = stepContainer.querySelectorAll('.step');

            if(((scrollPos + (wh/1.5)) > stepContainer_pos) && ( (scrollPos) < (stepContainer_pos + wh)) && !stepContainer.classList.contains('anime')) {
                stepContainer.classList.add('anime');
                for (let ii = 0; ii < steps.length; ii++) {
                    anime({
                        targets: steps[ii],
                        opacity: [0,1],
                        scale: [0.98,1],
                        translateX: [30,0],
                        delay: 100*ii,
                        duration: 700,
                        easing: 'easeOutSine',
                    });
                }
            }
        }
      }

      if(cache.animeStepsOpacity) {
        for (let i = 0; i < cache.animeStepsOpacity.length; i++) {
            const stepContainer = cache.animeStepsOpacity[i];
            const stepContainer_pos = utils.getOffsetTop(stepContainer);
            const steps = stepContainer.querySelectorAll('.step');

            if(((scrollPos + (wh/1.5)) > stepContainer_pos) && ( (scrollPos) < (stepContainer_pos + wh)) && !stepContainer.classList.contains('anime')) {
                stepContainer.classList.add('anime');
                for (let ii = 0; ii < steps.length; ii++) {
                    anime({
                        targets: steps[ii],
                        opacity: [0,1],
                        delay: 100*ii,
                        duration: 700,
                        easing: 'easeOutSine',
                    });
                }
            }
        }
      }

      if(cache.animeImg) {
        for (let i = 0; i < cache.animeImg.length; i++) {
            const img = cache.animeImg[i];
            const img_pos = utils.getOffsetTop(img);

            if(((scrollPos + (wh-100)) > img_pos) && ( (scrollPos) < (img_pos + wh)) && !img.classList.contains('anime')) {
                img.classList.add('anime');
                anime({
                    targets: img,
                    opacity: [0,1],
                    scale:[1.1, 1],
                    duration: 1000,
                    easing: 'easeOutSine',
                });
                anime({
                    targets: img.querySelector('img'),
                    translateX: ['-50%','-50%'],
                    translateY: ['-40%','-50%'],
                    duration: 1000,
                    easing: 'easeOutSine',
                });
            }
        }
      }

      if(cache.locIcon1) {
        const icon = cache.locIcon1;
        const icon_pos = utils.getOffsetTop(icon)-wh;

        if(((scrollPos) > icon_pos) && ( (scrollPos) < (icon_pos + wh))) {

            let percent = (((scrollPos)-icon_pos))/(wh*2);
            const animeIcon = anime({
                targets: icon,
                translateY: ['0%','-300%'],
                rotate: [0,45],
                duration: 100,
                easing: 'easeOutSine',
                autoplay: false,
            });
            animeIcon.seek((percent) * animeIcon.duration);
            
        }
      }

      if(cache.locIcon2) {
        const icon = cache.locIcon2;
        const icon_pos = utils.getOffsetTop(icon)-wh;

        if(((scrollPos) > icon_pos) && ( (scrollPos) < (icon_pos + wh))) {

            let percent = (((scrollPos)-icon_pos))/(wh*2);
            const animeIcon = anime({
                targets: icon,
                translateY: ['0%','-300%'],
                rotate: [0,-45],
                duration: 100,
                easing: 'easeOutSine',
                autoplay: false,
            });
            animeIcon.seek((percent) * animeIcon.duration);
            
        }
      }
      
    }

    initFunc();
  };
}
export { Animations }
